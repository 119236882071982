
                @import '@style/mixins';
                @import '@skillbox/front-box/dist/Functional/UiVariables/style';
              
.lms {
  overflow: hidden;
}

.lms__tag {
  display: block;
  width: fit-content;
  margin: 0 auto 16px;
  text-transform: uppercase;

  @include media(lg) {
    margin-bottom: 20px;
  }
}

.lms__title {
  margin-bottom: 32px;
  text-align: center;

  @include media(md) {
    width: 520px;
    margin: 0 auto 32px;
  }

  @include media(lg) {
    width: 776px;
    margin-bottom: 100px;
  }
}

.lms__list {
  @include ulres;
}

.lms__item {
  $img-left-xl-avif: 'https://cdn.skillbox.pro/wbd-front/landgen-static/blocks/lms/image2-xl.avif';
  $img-left-xl-webp: 'https://cdn.skillbox.pro/wbd-front/landgen-static/blocks/lms/image2-xl.webp';
  $img-left-xl-png: 'https://cdn.skillbox.pro/wbd-front/landgen-static/blocks/lms/image2-xl.png';

  $img-left-lg-avif: 'https://cdn.skillbox.pro/wbd-front/landgen-static/blocks/lms/image2-lg.avif';
  $img-left-lg-webp: 'https://cdn.skillbox.pro/wbd-front/landgen-static/blocks/lms/image2-lg.webp';
  $img-left-lg-png: 'https://cdn.skillbox.pro/wbd-front/landgen-static/blocks/lms/image2-lg.png';

  $img-right-xl-avif: 'https://cdn.skillbox.pro/wbd-front/landgen-static/blocks/lms/image1-xl.avif';
  $img-right-xl-webp: 'https://cdn.skillbox.pro/wbd-front/landgen-static/blocks/lms/image1-xl.webp';
  $img-right-xl-png: 'https://cdn.skillbox.pro/wbd-front/landgen-static/blocks/lms/image1-xl.png';

  $img-right-sm-avif: 'https://cdn.skillbox.pro/wbd-front/landgen-static/blocks/lms/image1-sm.avif';
  $img-right-sm-webp: 'https://cdn.skillbox.pro/wbd-front/landgen-static/blocks/lms/image1-sm.webp';
  $img-right-sm-png: 'https://cdn.skillbox.pro/wbd-front/landgen-static/blocks/lms/image1-sm.png';

  position: relative;
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 48px;

    @include media(lg) {
      margin-bottom: 100px;
    }
  }

  &:first-child::before {
    position: relative;
    width: calc(100% + 136px);
    margin-bottom: 32px;
    margin-left: -68px;
    padding-bottom: calc(100% / 3 * 1.23);
    background-image: url($img-right-sm-png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    content: '';

    @include media(360) {
      padding-bottom: calc(100% / 3 * 1.18);
    }

    @include media(md) {
      width: 768px;
      height: 210px;
      margin-left: -37px;
      padding-bottom: 0;
    }

    @include media(lg) {
      position: absolute;
      top: -60px;
      right: -108px;
      width: 568px;
      height: 154px;
      margin-bottom: 0;
    }

    @include media(xl) {
      right: -136px;
      width: 814px;
      height: 220px;
      background-image: url($img-right-xl-png);
    }
  }

  &:nth-child(2)::after {
    @include media(lg) {
      position: absolute;
      bottom: 0;
      left: -108px;
      display: block;
      width: 412px;
      height: 390px;
      background-image: url($img-left-lg-png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      content: '';
    }

    @include media(xl) {
      left: -136px;
      width: 520px;
      height: 492px;
      background-image: url($img-left-xl-png);
    }
  }

  @supports (background-image: image-set('pic.png' type('image/png')) ) {
    &:first-child::before {
      background-image: image-set(
        $img-right-sm-avif type('image/avif'),
        $img-right-sm-webp type('image/webp'),
        $img-right-sm-png type('image/png')
      );

      @include media(xl) {
        background-image: image-set(
          $img-right-xl-avif type('image/avif'),
          $img-right-xl-webp type('image/webp'),
          $img-right-xl-png type('image/png')
        );
      }
    }

    &:nth-child(2)::after {
      background-image: image-set(
        $img-left-lg-avif type('image/avif'),
        $img-left-lg-webp type('image/webp'),
        $img-left-lg-png type('image/png')
      );

      @include media(xl) {
        background-image: image-set(
          $img-left-xl-avif type('image/avif'),
          $img-left-xl-webp type('image/webp'),
          $img-left-xl-png type('image/png')
        );
      }
    }
  }
}

[data-theme='dark'] .lms__item {
  $img-dark-left-avif: 'https://cdn.skillbox.pro/wbd-front/landgen-static/blocks/lms/image2-dark.avif';
  $img-dark-left-webp: 'https://cdn.skillbox.pro/wbd-front/landgen-static/blocks/lms/image2-dark.webp';
  $img-dark-left-png: 'https://cdn.skillbox.pro/wbd-front/landgen-static/blocks/lms/image2-dark.png';

  $img-dark-right-avif: 'https://cdn.skillbox.pro/wbd-front/landgen-static/blocks/lms/image1-dark.avif';
  $img-dark-right-webp: 'https://cdn.skillbox.pro/wbd-front/landgen-static/blocks/lms/image1-dark.webp';
  $img-dark-right-png: 'https://cdn.skillbox.pro/wbd-front/landgen-static/blocks/lms/image1-dark.png';

  &:first-child::before {
    background-image: url($img-dark-right-png);
  }

  &:nth-child(2)::after {
    background-image: url($img-dark-left-png);
  }

  @supports (background-image: image-set('pic.png' type('image/png')) ) {
    &:first-child::before {
      background-image: image-set(
        $img-dark-right-avif type('image/avif'),
        $img-dark-right-webp type('image/webp'),
        $img-dark-right-png type('image/png')
      );
    }

    &:nth-child(2)::after {
      background-image: image-set(
        $img-dark-left-avif type('image/avif'),
        $img-dark-left-webp type('image/webp'),
        $img-dark-left-png type('image/png')
      );
    }
  }
}

.lms__text {
  @include media(md) {
    width: 432px;
  }

  @include media(lg) {
    width: 460px;
  }

  @include media(xl) {
    width: 482px;
  }
}

.lms__desc {
  color: var(--text-secondary-color);
}

.lms__media {
  position: relative;
  margin-top: 24px;
  border-radius: 20px;
  overflow: hidden;
  isolation: isolate;

  @include media(sm-max) {
    padding-top: calc(100% / 16 * 9);
  }

  @include media(md) {
    flex-shrink: 0;
    align-self: end;
    width: 696px;
    height: 392px;
    margin-top: 32px;
  }

  @include media(lg) {
    width: 694px;
    height: 390px;
    border-radius: 32px;
  }

  @include media(xl) {
    width: 874px;
    height: 492px;
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 28px;
    background-color: rgba(var(--accent-always-black-color-rgb), .04);
    isolation: isolate;
    content: '';

    @include media(xl) {
      border-radius: 32px;
    }
  }

  .ui-video,
  .ui-picture {
    height: 100%;
  }

  img,
  video {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;

    @include media(sm-max) {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
